.newNav-container {
  max-width: 815px;
  display: flex;
  gap: 0.75rem;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0.75rem 1rem;
  justify-content: space-between;
}

.newNav-ul {
  display: none;
  gap: 0.25rem;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.NavLink-body {
  font-size: 0.875rem;
  line-height: 1.25rem;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  color: #6f6f6f;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  color: inherit;
  text-decoration: inherit;
}
.NavLink-body:hover {
  color: #171717;
}

.bg-secondaryA {
  background-color: #00000012;
}

.mobileNave-top {
  position: relative;
  margin-left: auto;
}

.mobileNave-button {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem;
  border-radius: 0.5rem;
  color: #6f6f6f;
}

.mobileNave-panel {
  position: absolute;
  right: 0;
  z-index: 10;
  margin-top: 0.5rem;
  width: 10rem;
  transform-origin: top right;
  overflow: auto;
  border-radius: 0.75rem;
  background-color: #fffffff2;
  padding: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  opacity: 1;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
}

.mobileNave-grid {
  display: grid;
}

.mobileNave-items {
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  font-weight: 400;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.transition {
  transition: all 0.2s ease-out;
}

.opacity-0 {
  opacity: 0;
}

.translate-y-1 {
  transform: translateY(1rem);
}

.opacity-100 {
  opacity: 1;
}

.translate-y-0 {
  transform: translateY(0);
}

/* @media (hover: hover) and (pointer: fine). hover\:text-primary:hover {
    color: var(--gray12);
} */
/* Medium Device */

@media screen and (min-width: 768px) {
  .newNav-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .newNav-ul {
    display: flex;
  }
  .mobileNave-top {
    display: none;
  }
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
}

.nav-width {
  max-width: 930px;
  margin: auto;
  padding: 2rem;
}

.nav {
  height: calc(var(--header-height) + 1.5rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}

.nav__toggle {
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav__logo {
  width: 5.5rem;
  align-items: center;
  margin-top: 5px;
}

.nav__list {
  display: flex;
  column-gap: 2rem;
}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
}
.nav__icon,
.nav__close,
.nav__toggle {
  display: none;
}

a {
  color: var(--title-color-dark);
}

/* Active Link */
.active-link,
.nav__link:hover {
  color: #909090;
}

/* background changer */
.scroll-header {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}

.snsArea {
  position: fixed;
  bottom: 120px;
  right: 35px;
  z-index: 99999;
}

.pc_show {
  display: block;
}

.ttl {
  writing-mode: vertical-rl;
  font-size: 1.1rem;
  position: relative;
  letter-spacing: 0.08em;
}

.ttl::before {
  content: "";
  position: absolute;
  bottom: -65px;
  right: 13px;
  width: 1px;
  height: 60px;
  background: #222;
}

.snsList {
  text-align: center;
  margin-top: 75px;
}

/* Break Points */
/* Medium Device */
@media screen and (max-width: 768px) {
  .nav__logo {
    width: 5.5rem;
    align-items: center;
  }

  .nav {
    height: var(--header-height);
  }

  .nav__menu {
    position: fixed;
    top: 3rem;
    top: -100%;
    width: 100%;
    right: 0.5rem;
    height: 28%;
    background-color: var(--body-color);
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 1.5rem 1.5rem 0 0;
    transition: 1s ease-in-out;
    -webkit-border-radius: 1.5rem 1.5rem 0 0;
  }

  /* Show the menu */
  .show-menu {
    top: 3rem;
    right: 0.5rem;
    transition: 0.7s ease-in-out;
  }

  .nav__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  .nav__icon {
    font-size: 1.2rem;
  }

  .nav__close {
    /* position: absolute;
    right: 1.2rem;
    bottom: .5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--title-color); */
    opacity: 0 !important;
    transition: all 0.6s ease-in-out;
  }

  .nav__close:hover {
    color: var(--title-color-dark);
  }

  .nav__toggle {
    font-size: 1.1rem;
    cursor: pointer;
    z-index: 100;
  }

  .nav__icon,
  .nav__close,
  .nav__toggle {
    display: block;
  }

  .nav__list {
    /* display: table-cell; */
    vertical-align: middle;
    position: relative;
    padding-bottom: 180px;
    transition-timing-function: cubic-bezier(0.1, 0.57, 0.1, 1);
    transition-duration: 0ms;
    transform: translate(0px, 0px) translateZ(0px);
  }

  .nav__item {
    list-style-type: none;
    width: 100%;
    text-align: center;
    position: relative;
  }

  .snsArea {
    bottom: 16px;
    right: 20px;
    position: absolute;
  }
}

/* Medium Device */

@media screen and (max-width: 768px) {
  .nav-width {
    padding: 1rem;
  }
}

/* Small Device */
@media screen and (max-width: 350px) {
  .nav__menu {
    padding: 2rem 0.25rem 4rem;
  }

  .nav__list {
    column-gap: 0;
  }
}
