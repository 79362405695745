.project__box-container {
  max-width: 800px;
  padding-left: var(--mb-1-5);
  padding-right: var(--mb-1-5);
  margin-left: auto;
  margin-right: auto;
}

.project__h2-1 {
  font-size: 18px;
  color: #6f6f6f;
  margin-bottom: 1rem;
}

.projects__box-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.5rem;
  max-width: 600px;
  margin: auto;
}

.projects__box-li {
  padding-bottom: 1.75rem;
}

.project__desc-p {
  display: block;
  font-size: 15px;
  color: #171717;
}

.project__items-img {
  position: relative;
  width: 18rem;
  height: 8rem;
  border: 0.1px solid #e5e7eb;
  aspect-ratio: 1/1;
}

.project__items-img img {
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0px;
  color: transparent;
  max-width: 100%;
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
  object-fit: cover;
}

.project__section-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 472px;
}

.project__section-item h2 {
  font-size: 16px;
}

.project__link-container {
  margin-top: auto;
}

.project__link {
  border-bottom: 1px solid #171717;
}

/* Medium Device */
@media screen and (max-width: 768px) {
  .projects__box-item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    max-width: 380px;
    margin: auto;
  }

  .project__items-img {
    position: relative;
    width: 6rem;
    height: 6rem;
    aspect-ratio: 1/1;
  }

  .project__desc-p {
    display: none;
  }

  .project__link {
    display: none;
  }

  .project__section-item {
    gap: 0.5rem;
  }
}
