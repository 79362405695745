.aboutBody-top {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  animation: in 0.6s both;
  animation-delay: calc(3 * 0.13s);
}

.aboutBody-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.aboutBody-h2 {
  font-size: 16px;
  font-weight: 300;
  color: #6f6f6f;
  flex-shrink: 0;
}

.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("../../../public/profile-about.jpg");
  height: 300px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.profile-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .aboutBody-top {
    gap: 6rem;
  }

  .aboutBody-section {
    flex-direction: row;
    gap: 2.25rem;
  }

  .aboutBody-h2 {
    width: 7rem;
  }
}
