.service__container {
  grid-template-columns: repeat(3, max-content);
  justify-content: center;
  gap: 3rem;
  margin-top: 2rem;
}

.pt-h5 {
  margin-bottom: 0.5rem;
}

.service__title {
  font-size: var(--h3-font-size);
  margin-bottom: var(--mb-1);
  font-weight: var(--font-medium);
}

.service__button {
  color: var(--title-color);
  font-size: var(--small-font-size);
  display: inline-flex;
  align-items: center;
  column-gap: 0.25rem;
  cursor: pointer;
}

.service__button-icon {
  font-size: 1rem;
  transition: 0.3s;
}

.service__button:hover .service__button-icon {
  transform: translateX(0.25rem);
  -webkit-transform: translateX(0.25rem);
  -moz-transform: translateX(0.25rem);
  -ms-transform: translateX(0.25rem);
  -o-transform: translateX(0.25rem);
}

.service__model {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: var(--z-model);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.service__model-content {
  width: 500px;
  position: relative;
  background-color: var(--container-color);
  padding: 2rem 0.5rem 0.5rem 0.5rem;
  border-radius: 1.5rem;
}

.service__model-close {
  position: absolute;
  top: 0.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
}

.service__model-title,
.service__model-description {
  text-align: center;
}

.service__model-title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1);
}

.service__model-description {
  font-size: var(--small-font-size);
  padding: 0 3.5rem;
  margin-bottom: var(--mb-2);
}

.service__model-services {
  row-gap: 0.75rem;
}

.service__model-service {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.service_model-icon {
  color: var(--title-color);
  font-size: 1.1rem;
}

.service__model-info {
  font-size: var(--small-font-size);
}

/* Active model */
.active-model {
  opacity: 1;
  visibility: visible;
}

.work__card {
  background-color: var(--container-color);
  border: solid 2px #9d9d9d;
  border-radius: 8px;
  padding: 1.25rem;
  border-radius: 1rem;
}

.work__img {
  width: 295px;
  border-radius: 1rem;
  margin-bottom: var(--mb-1);
}

.work__img2 {
  width: 85%;
  height: 50%;
  object-fit: cover;
  object-position: center top;
  margin: auto;
  display: block;
  border-radius: 5px;
  padding: 5px 0px;
}

.work__cover {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  -webkit-box-align: center;
  align-items: center;
  flex-flow: row wrap;
  border-top: 1px solid rgb(35, 41, 70);
}

.ilgNXF {
  font-size: 16px;
  color: rgb(35, 41, 70);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 3px;
  flex-wrap: nowrap;
  transition: transform 0.3s ease 0s;
  padding: 10px 0px;
}

.ilgNXF:hover {
  transform: translateY(-3px);
}

.psblj {
  width: 1px;
  height: 50px;
  background: rgb(35, 41, 70);
}

.work__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-5);
}

.work__button {
  color: var(--text-color);
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}

.work__button-icon {
  font-size: 1rem;
  transition: 0.4s;
}

.work__button:hover .work__button-icon {
  transform: translateX(0.25rem);
}

.onStep {
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-fill-mode: both;
}
/* Break Points */
/* Large Device */
@media screen and (max-width: 1120px) {
  .service__container {
    grid-template-columns: repeat(2, max-content);
  }

  .service__container {
    gap: 1.25rem;
  }

  .work__card {
    padding: 1rem;
  }

  .work__img {
    margin-bottom: 0.75rem;
  }

  .work__title {
    margin-bottom: 0.25rem;
  }
}

/* Medium Device */
@media screen and (max-width: 768px) {
  .service__container {
    grid-template-columns: repeat(2, 248px);
  }

  .work__img2 {
    width: 90%;
    height: 45%;
  }

  .work__cover-content {
    width: 95%;
    padding-bottom: 5px;
  }

  .service__model-title {
    font-size: 16px;
    padding-bottom: 10px;
  }

  .service__model-description {
    font-size: 13px;
    padding-bottom: 10px;
  }

  .ilgNXF {
    font-size: 14px;
    gap: 1px;
    padding: 0px;
  }
}

@media screen and (max-width: 576px) {
  .service__container {
    grid-template-columns: repeat(2, 1fr);
  }

  .work__img {
    width: 100%;
    border-radius: 0.5rem;
  }

  .work__card {
    padding: 0.5rem;
  }
}

/* Small Device */
@media screen and (max-width: 480px) {
  .service__container {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* @media screen and (max-width: 480px) {
    .ilgNXF {
        font-size: 10px;
    }
} */
