.error__container {
    text-align: center;
    margin-top: 10rem;
    height: 20rem;
}

.face {
        font-size: 3rem;
        animation: shake 0.1s linear infinite;
    }
    
    @keyframes shake {
        0% {
        transform: translate(0px, 0px);
        }
        20% {
        transform: translate(4px, -4px);
        }
        40% {
        transform: translate(-2px, 4px);
        }
        60% {
        transform: translate(1px, 1px);
        }
        80% {
        transform: translate(-4px, -2px);
        }
        100% {
        transform: translate(0px, 0px);
        }
    }
    
    .error__page-button {
        background-color: var(--title-color);
        border: 4px solid var(--title-color);
        border-radius: 1rem;
        color: var(--container-color);
        display: inline-block;
        font-weight: 1rem;
        padding: 0.8rem 2rem;
        transition: transform .3s ease 0s;
    }

    .error__page-button:hover {
        transform: translateY(-3px);
    }