.project_top-child-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-top: 4rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 700px;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  animation-delay: calc(var(--index) * 0.13s);
}

.project_top-intro {
  display: flex;
  gap: 0.75rem;
  color: #6f6f6f;
}

.project_top-child-body {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding: 0.5rem 1rem;
  animation-delay: calc(var(--index) * 0.13s);
}

.project_top-child-bottom {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  gap: 5rem;
  padding: 0.5rem 1rem;
  margin-left: auto;
  margin-right: auto;
}

.p-items-link {
  font-size: 16px;
  color: #6f6f6f;
  text-underline-offset: 4px;
  text-decoration: underline;
}

.p-items-link:hover {
  color: #171717;
}

/* Medium Device */
@media screen and (min-width: 768px) {
  .project_top-child-container {
    padding-top: 5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .project_top-child-body {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .project_top-child-bottom {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
