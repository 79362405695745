.home__container {
  row-gap: 6rem;
  max-width: 800px;
  padding-left: var(--mb-1-5);
  padding-right: var(--mb-1-5);
  margin-left: auto;
  margin-right: auto;
}

.home__section-container {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.home__name-intro h1 {
  font-size: 1.875rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 2.25rem;
  /* animation: in .6s both;
    animation-delay: calc(var(--index) * .13s); */
}

.home__profile-pic {
  display: flex;
  gap: 3.5rem;
  flex-direction: row;
  align-items: center;
}

.home__profile-pic img {
  width: 150px;
  height: 200px;
  max-width: 100%;
  height: auto;
  color: transparent;
  background-color: #ededed;
  border-radius: 3px;
}

.home__profile-pic p {
  color: #171717;
}

.home__ul {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.home__a {
  display: flex;
  align-items: center;
  text-underline-offset: 4px;
  text-decoration-line: none;
  -moz-text-decoration-line: none;
  gap: 0.5rem;
  color: inherit;
  text-decoration: inherit;
}

.home__icon {
  width: 1.25rem;
  height: 1.25rem;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(3.75rem);
  }
}

.home__scroll-name {
  color: var(--title-color);
  font-weight: var(--font-medium);
  margin-right: var(--mb-0-25);
  margin-left: 2px;
}

.home__scroll-arrow {
  font-size: 1.25rem;
  color: var(--title-color);
}

/* loading animation */

.fadeUp-1 {
  transform: translateY(5px);
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: 1s;
  transition-timing-function: linear;
  -webkit-transform: translateY(5px);
  -moz-transform: translateY(5px);
  -ms-transform: translateY(5px);
  -o-transform: translateY(5px);
}

.onStep {
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-fill-mode: both;
}

/* Break Points */
/* Large Device */
@media screen and (max-width: 1120px) {
  .home__content {
    /* grid-template-columns: 400px repeat(1, 1fr); */
    grid-template-columns: 416px 316px;
  }

  .home__section-container {
    margin-top: 5rem;
  }
}

/* Medium Device */
@media screen and (max-width: 768px) {
  .home__profile-pic {
    flex-direction: column;
    align-items: baseline;
  }

  .home__ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

@media screen and (max-width: 576px) {
}

/* Small Device */
@media screen and (max-width: 466px) {
}

/* Small Device */
@media screen and (max-width: 350px) {
}
