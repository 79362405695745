.skills__container {
  grid-template-columns: repeat(2, 350px);
  column-gap: 3rem;
  justify-content: center;
}

.skills__content {
  background-color: var(--container-color);
  padding: 2rem 4rem;
  border: solid 2px #9d9d9d;
  border-radius: 8px;
}

.skills__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  text-align: center;
  margin-bottom: var(--mb-1-5);
}

.skills__box {
  display: flex;
  justify-content: center;
  column-gap: 2.5rem;
}

.skills__group {
  display: grid;
  align-items: flex-start;
  row-gap: 1rem;
}

.skills__data {
  display: flex;
  column-gap: 0.5rem;
}

.skills .bx-check-circle {
  font-size: 1rem;
  color: var(--title-color);
}

.skills__name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  line-height: 18px;
}

.skills__level {
  font-size: var(--tiny-font-size);
}

.about__h1 {
  font-family: Abel, sans-serif;
  color: rgb(35, 41, 70);
  font-weight: 500;
  font-size: 36px;
  letter-spacing: 5px;
  padding: 20px 0px 0px;
  text-align: center;
}

.about__div {
  width: 70vw;
  text-align: center;
  margin: auto;
}

.about__ul {
  width: 60vw;
  margin: 0px auto;
  padding-top: 20px;
}

.about__li {
  font-size: 18px;
  color: rgb(35, 41, 70);
  padding: 5px 0px;
  margin-top: 24px;
  line-height: 156%;
}

.about__span {
  font-weight: bold;
  letter-spacing: 3px;
  background: #333333;
  padding: 3px;
  color: rgb(255, 255, 254);
}

.volunteer__span {
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 20px;
}

.vl_plp {
  color: #6d6d6d;
}

.about__flex {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 700px;
  margin-left: var(--mb-1-5);
  margin-right: var(--mb-1-5);
}
.about__flex h2 {
  flex-shrink: 0;
  color: #6f6f6f;
  font-size: 22px;
}

.about__content-div {
  gap: 1.5rem;
  flex-direction: column;
  display: flex;
}

.about__content-div {
  color: #171717;
}

/* Break Points */
/* Large Device */
@media screen and (min-width: 700px) {
  .about__flex {
    margin: auto;
    padding-left: var(--mb-1-5);
    padding-right: var(--mb-1-5);
  }
}

@media screen and (min-width: 768px) {
  .about__flex {
    display: flex;
    flex-direction: row;
    gap: 2.25rem;
    max-width: 800px;
  }

  .about__flex h2 {
    width: 7rem;
    font-size: 18px;
  }
}

@media screen and (min-width: 1120px) {
  .about__flex {
    max-width: 800px;
  }
  .skills__container {
    grid-template-columns: max-content;
    row-gap: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .about__h1 {
    font-size: 26px;
  }
}

@media screen and (max-width: 576px) {
  .skills__container {
    grid-template-columns: 1fr;
  }

  .skills__content {
    padding: 1.5rem;
  }

  .about__h1 {
    font-size: 20px;
  }

  .about__ul {
    width: 80vw;
  }

  .about__li {
    font-size: 14px;
  }
}

/* Small Device */
@media screen and (max-width: 350px) {
  .skills__box {
    column-gap: 1.25rem;
  }

  .skills__name {
    font-size: var(--small-font-size);
  }
}
